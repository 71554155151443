var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tp-table-scroll__with-title tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":_vm.returnSerialStatusRequest.value === 'loading-getReturnSerials',"loading-text":"Đang tải dữ liệu","items":_vm.returnSerials,"item-key":"id","show-select":""},scopedSlots:_vm._u([{key:"item.serial_number",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.serial_number)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.serial_number)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])]}},{key:"item.option_sku",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.option_sku)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.option_sku)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])]}},{key:"item.created_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.created_price))+" ")]}},{key:"item.option_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.option_price))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"datatable-action text-decoration-underline text-body-2",class:{ 'primary--text': hover },on:{"click":function($event){return _vm.importReturnSerial(item.id)}}},[_vm._v(" Giữ SKU ")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"datatable-action text-decoration-underline text-body-2 mt-1",class:{ 'primary--text': hover },on:{"click":function($event){return _vm.openModalUpdateSku(item)}}},[_vm._v(" Thay đổi SKU ")])]}}],null,true)})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }