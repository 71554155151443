<template>
  <div class="action mr-5 mb-4">
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between">
      <div class="action-row--col flex-grow-1 pr-5">
        <tp-search-option
          :options="searchOptions"
          :value="searchKey"
          @search="updateSearchKey($event)"
        ></tp-search-option>
      </div>
    </div>
    <!-- End: Action row -->
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between mt-4">
      <div class="action-row--col align-center">
        <!-- Start: Show filter dropdown -->
        <tp-btn-overflow
          btn-class="white rounded-lg"
          :items="showFilterList"
          :selected-value="selectedShowFilterId"
          @change="selectedShowFilterIndex = $event"
          @select="selectShowFilter($event)"
        ></tp-btn-overflow>
        <!-- End: Show filter dropdown -->
        <!-- Start: Action -->
        <v-btn
          v-if="selectedReturnSerials.length > 0"
          class="rounded-lg text-body-1 font-weight-light ml-3 text-none"
          color="white"
          depressed
          @click="importReturnSerial()"
        >
          Nhập lại giữ SKU
        </v-btn>
        <!-- End: Action -->
        <div class="ml-3" v-if="selectedReturnSerials.length > 0">
          Đã chọn <strong>{{ this.selectedReturnSerials.length }}</strong> bản
          ghi
        </div>
      </div>
      <div class="action-row--col d-flex align-center">
        <div class="text-body-2">
          {{ paginationInfo.from }} - {{ paginationInfo.to }} trong số
          {{ paginationInfo.itemTotal }}
        </div>
        <v-pagination
          v-model="curPage"
          color="primary"
          :length="paginationInfo.pageTotal"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
    <!-- End: Action row -->
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number
    },
    selectedReturnSerials: {
      type: Array,
      required: true
    },
    selectedShowFilterId: {
      type: Number
    },
    searchKey: {
      type: String
    }
  },
  data() {
    return {
      actionList: [{ id: "delete", text: "Xóa" }],
      searchOptions: [
        { type: "search", label: "Tìm kiếm theo serial" }
        // { type: "option/search", label: "Theo mã, tên SKU" },
        // { type: "serial/search", label: "Theo serial" }
      ],
      showFilterList: [
        { id: 25, text: "Hiển thị 25" },
        { id: 50, text: "Hiển thị 50" },
        { id: 100, text: "Hiển thị 100" }
      ]
    };
  },
  computed: {
    returnSerialStatusRequest() {
      return this.$store.getters["RETURN_SERIAL/statusRequest"];
    },
    curPage: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("updateCurrentPage", val);
      }
    },
    paginationInfo() {
      return this.$store.getters["RETURN_SERIAL/paginationInfo"];
    }
  },
  methods: {
    importReturnSerial() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Nhập lại kho và giữ SKU của <strong>"${this.selectedReturnSerials.length}"</strong>đã chọn</span>`,
          message:
            "Các serial được chọn sẽ trở lại kho với thông tin SKU được giữ nguyên.",
          confirmBtn: {
            color: "red accent-2",
            text: "Tiếp tục",
            onClickHandler: async () => {
              const routeQuery = this.$route.query;

              await this.$store.dispatch("RETURN_SERIAL/importReturnSerial", {
                data: {
                  ids: this.selectedReturnSerials.map(item => item.id)
                },
                routeQuery: {
                  search: routeQuery.search,
                  per_page: parseInt(routeQuery.limit),
                  cur_page: parseInt(routeQuery.page)
                }
              });
              if (
                this.returnSerialStatusRequest.value ===
                "success-importReturnSerial"
              ) {
                this.$emit("updateSelectedReturnSerials", []);
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã nhập kho và giữ SKU thành công"
                  }
                });
              }
            }
          }
        }
      });
    },

    selectShowFilter(val) {
      this.$emit("updateItemPerPage", val.id);
    },

    updateSearchKey(val) {
      this.$emit("updateSearchKey", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.action {
  &-row {
    &--col {
      display: flex;
    }
  }
}
</style>
