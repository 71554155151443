<template>
  <tp-modal name="modal-return-serial-update-sku" max-width="768px">
    <v-card flat slot-scope="props">
      <v-card-title class="pb-4">
        <div class="font-weight-bold">
          Cập nhật SKU cho serial #{{
            props.payload.serial && props.payload.serial.serial_number
          }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          text
          :disabled="!selectedProductOption"
          :loading="false"
          @click="
            selectedProductOption
              ? importReturnSerial(props.payload.serial.id)
              : null
          "
        >
          Lưu
        </v-btn>
        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card class="pa-5" flat>
          <!-- Start: Search Area -->
          <div class="d-flex justify-center">
            <div class="search-box d-flex align-center">
              <v-autocomplete
                v-model="selectedProductOption"
                class="text-body-1 rounded-lg mr-3"
                clearable
                dense
                flat
                hide-details
                :items="searchProductOptions"
                item-text="product_name"
                item-value="id"
                :menu-props="{ maxWidth: '332px' }"
                no-data-text="Không có dữ liệu"
                no-filter
                placeholder="Tìm SKU"
                prepend-inner-icon="mdi-magnify"
                return-object
                outlined
                :search-input.sync="productOptionSearchKey"
              >
                <template v-slot:item="data">
                  <v-list-item-content :title="data.item.name">
                    <v-list-item-title
                      class="font-weight-medium"
                      v-html="data.item.product_name"
                    ></v-list-item-title>
                    <div class="grey--text text--darken-2 text-subtitle-2">
                      <span class="font-weight-bold"
                        >{{ data.item.SKU }} -
                      </span>
                      <span
                        class="item-subtitle"
                        v-html="data.item.name"
                      ></span>
                    </div>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-btn
                class="cyan lighten-5 btn-add px-0"
                height="40px"
                width="40px"
                depressed
                color="primary"
                icon
                @click="openModalProduct()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
          <!-- End: Search Area -->
          <v-row class="mt-8">
            <v-col class="py-0" cols="6">
              <v-card class="tp-border-thin pa-4" flat>
                <v-card-title
                  class="justify-center font-weight-bold py-0 px-0 mb-3"
                  >SKU cũ</v-card-title
                >
                <div class="text-body-1 mb-1">
                  <span class="font-weight-bold">Tên sản phẩm:</span>
                  {{
                    props.payload.serial && props.payload.serial.product_name
                  }}
                </div>
                <div class="text-body-1  mb-1">
                  <span class="font-weight-bold">SKU:</span>
                  {{ props.payload.serial && props.payload.serial.option_sku }}
                  -
                  <span
                    v-if="props.payload.serial"
                    class="p-tag"
                    v-html="props.payload.serial.option_name"
                  ></span>
                </div>
                <div class="text-body-1  mb-1">
                  <span class="font-weight-bold">Giá bán:</span>
                  {{
                    props.payload.serial &&
                      props.payload.serial.option_price | formatCurrency
                  }}
                </div>
              </v-card>
            </v-col>
            <!-- Start: New SKU info-->
            <v-col class="py-0" cols="6">
              <v-card class="fill-height tp-border-thin pa-4" flat>
                <v-card-title
                  class="justify-center font-weight-bold py-0 px-0 mb-3"
                  >SKU mới</v-card-title
                >
                <template v-if="selectedProductOption">
                  <div class="text-body-1 mb-1">
                    <span class="font-weight-bold">Tên sản phẩm:</span>
                    {{ selectedProductOption.product_name }}
                  </div>
                  <div class="text-body-1  mb-1">
                    <span class="font-weight-bold">SKU:</span>
                    {{ selectedProductOption.SKU }} -
                    <span
                      class="p-tag"
                      v-html="selectedProductOption.name"
                    ></span>
                  </div>
                  <div class="text-body-1  mb-1">
                    <span class="font-weight-bold">Giá bán:</span>
                    {{ selectedProductOption.price | formatCurrency }}
                  </div>
                </template>
                <template v-else>
                  <span class="grey--text text--darken-2">
                    Chưa có SKU được chọn.
                  </span>
                </template>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  data() {
    return {
      productOptionSearchKey: null,
      selectedProductOption: null
    };
  },
  computed: {
    returnSerialStatusRequest() {
      return this.$store.getters["RETURN_SERIAL/statusRequest"];
    },
    searchProductOptions() {
      return this.$store.getters["PRODUCT_OPTION/searchProductOptions"];
    },
    serialRequestStatus() {
      return this.$store.getters["SERIAL/statusRequest"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  watch: {
    productOptionSearchKey(val) {
      if (val && val.length > 0) {
        this.$store.dispatch("PRODUCT_OPTION/searchProductOptions", {
          search: val
        });
      }
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-return-serial-update-sku" });
    },

    openModalProduct() {
      this.$modal.show({
        name: "modal-product"
      });
    },

    async importReturnSerial(serialId) {
      const routeQuery = this.$route.query;

      // Import request
      await this.$store.dispatch("RETURN_SERIAL/importReturnSerial", {
        data: {
          ids: [serialId]
        },
        routeQuery: {
          search: routeQuery.search,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Update SKU
      if (
        this.returnSerialStatusRequest.value === "success-importReturnSerial"
      ) {
        await this.updateSKU(serialId, this.selectedProductOption.id);
      }
    },

    async updateSKU(serialId, optionId) {
      await this.$store.dispatch("SERIAL/updateSKU", {
        serial_id: serialId,
        new_option_id: optionId
      });

      if (this.serialRequestStatus.value === "success-update-sku") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã thay đổi SKU và nhập lại kho"
          }
        });
        this.selectedProductOption = null;
        this.productOptionSearchKey = null;
        this.closeModal();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search-box {
  min-width: 200px;
  width: 80%;
}
.p-tag {
  ::v-deep p {
    display: inherit;
    margin-bottom: 0 !important;
  }
}
</style>
