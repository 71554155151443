<template>
  <div class="pl-5">
    <div class="font-weight-bold text-h5 mb-4">Hàng trả chờ nhập</div>
    <!-- Start: Top actions -->
    <top-action
      :current-page="currentPage"
      :selected-return-serials="selectedReturnSerials"
      :selected-show-filter-id="itemPerPage"
      :search-key="searchKey"
      @updateCurrentPage="updateCurrentPage($event)"
      @updateItemPerPage="updateItemPerPage($event)"
      @updateSearchKey="updateSearchKey($event)"
      @updateSelectedBrands="selectedReturnSerials = $event"
    ></top-action>
    <!-- End: Top actions -->
    <!-- Start: Data table -->
    <div class="mr-5">
      <data-table-items
        :selected-return-serials="selectedReturnSerials"
        @updateSelectedReturnSerials="selectedReturnSerials = $event"
      ></data-table-items>
    </div>
    <!-- End: Data table -->
    <modal-update-sku></modal-update-sku>
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import ModalUpdateSku from "./components/modal/ModalUpdateSku";
import TopAction from "./components/TopAction";

export default {
  components: {
    DataTableItems,
    ModalUpdateSku,
    TopAction
  },
  data() {
    return {
      currentPage: 1,
      itemPerPage: 50,
      selectedReturnSerials: [],
      searchKey: null
    };
  },
  watch: {
    "$route.query"(val) {
      this.getReturnSerials(val);
    }
  },
  async created() {
    const route = this.$route;

    await this.getReturnSerials(route.query);
  },
  methods: {
    async pushRouter() {
      await this.$router.push({
        name: "goods_return-serials",
        query: {
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    async getReturnSerials(query) {
      if (query.page) {
        // Set current page
        this.currentPage = parseInt(query.page);
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set search key
        this.searchKey = query.search || null;

        // Get products
        await this.$store.dispatch("RETURN_SERIAL/getReturnSerials", {
          search: this.searchKey,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedReturnSerials = [];
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedReturnSerials = [];
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
