<template>
  <v-data-table
    v-model="selected"
    class="tp-table-scroll__with-title tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="returnSerialStatusRequest.value === 'loading-getReturnSerials'"
    loading-text="Đang tải dữ liệu"
    :items="returnSerials"
    item-key="id"
    show-select
  >
    <template v-slot:[`item.serial_number`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.serial_number)"
          >
            {{ item.serial_number }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.option_sku`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.option_sku)"
          >
            {{ item.option_sku }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.created_price`]="{ item }">
      {{ item.created_price | formatCurrency }}
    </template>

    <template v-slot:[`item.option_price`]="{ item }">
      {{ item.option_price | formatCurrency }}
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <v-hover v-slot:default="{ hover }">
        <div
          class="datatable-action text-decoration-underline text-body-2"
          :class="{ 'primary--text': hover }"
          @click="importReturnSerial(item.id)"
        >
          Giữ SKU
        </div>
      </v-hover>
      <v-hover v-slot:default="{ hover }">
        <div
          class="datatable-action text-decoration-underline text-body-2 mt-1"
          :class="{ 'primary--text': hover }"
          @click="openModalUpdateSku(item)"
        >
          Thay đổi SKU
        </div>
      </v-hover>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    selectedReturnSerials: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Serial",
          align: "start",
          sortable: false,
          value: "serial_number"
        },
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "product_name"
        },
        {
          text: "SKU",
          align: "start",
          sortable: false,
          value: "option_sku"
        },
        {
          text: "Giá nhập",
          align: "start",
          sortable: false,
          value: "created_price"
        },
        {
          text: "Giá bán",
          align: "start",
          sortable: false,
          value: "option_price"
        },
        {
          text: "Phiếu trả",
          align: "start",
          sortable: false,
          value: "phieuTraCode"
        },
        {
          text: "Ngày trả",
          align: "start",
          sortable: false,
          value: "phieuTraDate"
        },
        {
          text: "Hành động",
          align: "center",
          sortable: false,
          value: "action"
        }
      ]
    };
  },
  computed: {
    returnSerials() {
      return this.$store.getters["RETURN_SERIAL/returnSerials"];
    },
    returnSerialStatusRequest() {
      return this.$store.getters["RETURN_SERIAL/statusRequest"];
    },
    selected: {
      get() {
        return this.selectedReturnSerials;
      },
      set(val) {
        this.$emit("updateSelectedReturnSerials", val);
      }
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    copyToClipboard(str) {
      const el = document.createElement("textarea");

      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã sao chép vào bộ nhớ tạm"
        }
      });
    },

    async importReturnSerial(serialId) {
      const routeQuery = this.$route.query;

      // Import request
      await this.$store.dispatch("RETURN_SERIAL/importReturnSerial", {
        data: {
          ids: [serialId]
        },
        routeQuery: {
          search: routeQuery.search,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Update SKU
      if (
        this.returnSerialStatusRequest.value === "success-importReturnSerial"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã giữ SKU và nhập lại kho"
          }
        });
      }
    },

    openModalUpdateSku(item) {
      this.$modal.show({
        name: "modal-return-serial-update-sku",
        payload: {
          serial: item
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  &-action {
    cursor: pointer;
    transition: all 0.4s ease;
  }
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
